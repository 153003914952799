import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import HtmlContent from "@src/components/core-value-html"

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        content,
        images: { header: headerImage },
        seo: pageSeo,
      },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "static-content",
  })
  const indexedStaticUris = [
    "/about-10adventures-tours/",
    "/accommodation-policy/",
    "/advertising-policy/",
    "/booking-terms-and-conditions/",
    "/booking-terms-and-conditions-2024/",
    "/cookie-policy/",
    "/guides/",
    "/happiness-guarantee/",
    "/hike-difficulty-assessment/",
    "/our-contributors/",
    "/privacy-policy/",
    "/terms-of-use/",
    "/tour-difficulty-assessment/",
  ]
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [
            pageSeo.metaRobotsNofollow,
            indexedStaticUris.includes(pageUri) ? "index" : "noindex",
          ],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <ResponsiveSection>
        <HtmlContent
          html={
            pageUri === "/booking-terms-and-conditions-2024/"
              ? content.replace(
                  "<h2>9. Cancellation by the traveller</h2>",
                  `<h2 id="cancel">9. Cancellation by the traveller</h2>`
                )
              : content
          }
          tableStyle
          imageBlocks
        />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
